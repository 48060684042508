import React, { useState } from "react";
import { Grid, InputLabel, Checkbox, FormControlLabel, IconButton, Button } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import jsPDF from "jspdf";
import DeleteIcon from "@mui/icons-material/Delete";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

const RegistroHelmintos = () => {
  const [formulario, setFormulario] = useState({
    profesional: "",
    laboratorio: "",
    can: false,
    fel: false,
    prueba: false,
    referencia: false,
  });

  const [secciones, setSecciones] = useState([
    {
      id: 1,
      identificacionAnimal: "",
      especieParasita: "",
      valoresHPG: {
        basal: { fecha: "", valor: "" },
        dia15: { fecha: "", valor: "" },
        dia30: { fecha: "", valor: "" },
      },
    },
  ]);

  const handleChange = (key, value) => {
    setFormulario((prev) => ({ ...prev, [key]: value }));
  };

  const handleChangeSection = (id, field, value) => {
    setSecciones((prev) =>
      prev.map((section) =>
        section.id === id ? { ...section, [field]: value } : section
      )
    );
  };

  const handleHPGChange = (id, dia, field, value) => {
    setSecciones((prev) =>
      prev.map((section) =>
        section.id === id
          ? {
            ...section,
            valoresHPG: {
              ...section.valoresHPG,
              [dia]: { ...section.valoresHPG[dia], [field]: value },
            },
          }
          : section
      )
    );
  };

  const handleFieldChange = (field) => {
    setFormulario((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleAddSection = () => {
    if (secciones.length < 10) {
      setSecciones((prev) => [
        ...prev,
        {
          id: prev.length + 1,
          identificacionAnimal: "",
          especieParasita: "",
          valoresHPG: {
            basal: { fecha: "", valor: "" },
            dia15: { fecha: "", valor: "" },
            dia30: { fecha: "", valor: "" },
          },
        },
      ]);
    }
  };

  const handleRemoveSection = (id) => {
    setSecciones((prev) => prev.filter((section) => section.id !== id));
  };

  const generarPDF = () => {
    const doc = new jsPDF();
    doc.text("Planilla de Registro de Recuento Parasitario – Helmintos", 10, 10);
    doc.text(`Profesional Actuante: ${formulario.profesional}`, 10, 20);
    doc.text(`Laboratorio: ${formulario.laboratorio}`, 10, 30);
    doc.text(`Can: ${formulario.can ? "Sí" : "No"}, Fel: ${formulario.fel ? "Sí" : "No"}`, 10, 40);
    doc.text(`Prueba: ${formulario.prueba ? "Sí" : "No"}, Referencia: ${formulario.referencia ? "Sí" : "No"}`, 10, 50);

    secciones.forEach((section, index) => {
      const offset = 60 + index * 30;
      doc.text(`Sección ${index + 1}:`, 10, offset);
      doc.text(`Identificación Animal: ${section.identificacionAnimal}`, 10, offset + 10);
      doc.text(`Especie Parásita: ${section.especieParasita}`, 10, offset + 20);
      doc.text(`HPG Basal: Fecha ${section.valoresHPG.basal.fecha}, Valor ${section.valoresHPG.basal.valor}`, 10, offset + 30);
    });

    doc.save("registro_parasitario.pdf");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox>
        <SoftTypography variant="h5" fontWeight="bold" mb={2} sx={{ fontSize: "1.2rem" }}>
          Registro de Recuento Parasitario – Helmintos
        </SoftTypography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ fontSize: '1rem' }}>Profesional Actuante</InputLabel>
            <SoftInput
              sx={{ fontSize: '0.9rem' }}
              placeholder="Ingrese nombre"
              value={formulario.profesional}
              onChange={(e) => handleChange("profesional", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ fontSize: '1rem' }}>Laboratorio</InputLabel>
            <SoftInput
              sx={{ fontSize: '0.9rem' }}
              placeholder="Ingrese laboratorio"
              value={formulario.laboratorio}
              onChange={(e) => handleChange("laboratorio", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} ml={2}>
            <SoftBox display="flex" alignItems="center" gap={2}>
              {["can", "fel", "prueba", "referencia"].map((field) => (
                <FormControlLabel
                  key={field}
                  control={<Checkbox checked={formulario[field]} onChange={() => handleFieldChange(field)} />}
                  label={field.charAt(0).toUpperCase() + field.slice(1)}
                  sx={{ fontSize: '1rem' }}
                />
              ))}
            </SoftBox>
          </Grid>

          {secciones.map((section) => (
            <React.Fragment key={section.id}>
              <Grid item xs={12} sm={6}>
                <InputLabel sx={{ fontSize: '1rem' }}>Identificación Animal</InputLabel>
                <SoftInput
                  sx={{ fontSize: '0.9rem' }}
                  placeholder="Ingrese identificación"
                  value={section.identificacionAnimal}
                  onChange={(e) =>
                    handleChangeSection(section.id, "identificacionAnimal", e.target.value)
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel sx={{ fontSize: '1rem' }}>Especie Parásita</InputLabel>
                <SoftInput
                  sx={{ fontSize: '0.9rem' }}
                  placeholder="Ingrese especie"
                  value={section.especieParasita}
                  onChange={(e) =>
                    handleChangeSection(section.id, "especieParasita", e.target.value)
                  }
                />
              </Grid>

              {["basal", "dia15", "dia30"].map((dia) => (
                <Grid item xs={12} sm={4} key={`${section.id}-${dia}`}>
                  <SoftTypography variant="subtitle1" sx={{ fontSize: "1.1rem" }}>
                    {`HPG ${dia.toUpperCase()}`}
                  </SoftTypography>
                  <InputLabel sx={{ fontSize: '1rem' }}>Fecha</InputLabel>
                  <SoftInput
                    type="date"
                    sx={{ fontSize: '0.9rem' }}
                    value={section.valoresHPG[dia].fecha}
                    onChange={(e) =>
                      handleHPGChange(section.id, dia, "fecha", e.target.value)
                    }
                  />
                  <InputLabel sx={{ fontSize: '1rem' }}>Valor</InputLabel>
                  <SoftInput
                    type="number"
                    sx={{ fontSize: '0.9rem' }}
                    value={section.valoresHPG[dia].valor}
                    onChange={(e) =>
                      handleHPGChange(section.id, dia, "valor", e.target.value)
                    }
                  />
                </Grid>
              ))}

              <Grid item xs={12}>
                <IconButton
                  onClick={() => handleRemoveSection(section.id)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}

          <Grid item xs={12}>
            <SoftButton
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleAddSection}
              disabled={secciones.length >= 10}
              sx={{ mt: 2 }}
            >
              Añadir Sección
            </SoftButton>
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center">
            <SoftButton variant="contained" color="primary" onClick={generarPDF}>
              Generar PDF
            </SoftButton>
          </Grid>
        </Grid>

      </SoftBox>
    </DashboardLayout>
  );
};

export default RegistroHelmintos;

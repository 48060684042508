import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Grid, InputLabel, Checkbox, FormControlLabel, Paper, Divider } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { jsPDF } from "jspdf";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

const RegistroVisitasForm = ({ registro = {}, setRegistro }) => {
  const [visitas, setVisitas] = useState([
    { dia: "Día 0", pulgas: "", garrapatas: "", fecha: "", localizacionGarrapatas: "" },
    { dia: "24 hs", pulgas: "", garrapatas: "", fecha: "", localizacionGarrapatas: "" },
    { dia: "Día 7", pulgas: "", garrapatas: "", fecha: "", localizacionGarrapatas: "" },
    { dia: "Día 15", pulgas: "", garrapatas: "", fecha: "", localizacionGarrapatas: "" },
    { dia: "Día 21", pulgas: "", garrapatas: "", fecha: "", localizacionGarrapatas: "" },
    { dia: "Día 28", pulgas: "", garrapatas: "", fecha: "", localizacionGarrapatas: "" },
  ]);

  const [checkboxState, setCheckboxState] = useState({
    prueba: false,
    referencia: false,
  });

  const handleFieldChange = (field) => {
    setCheckboxState(prevState => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text("PROTOCOLO: PLANILLA DE REGISTRO DE VISITAS PARA RECUENTO PARASITARIO", 10, 10);

    // Datos Generales
    doc.text("Código:", 10, 20);
    doc.text(registro.codigo || "", 30, 20);
    doc.text("Fecha de Vigencia:", 60, 20);
    doc.text(registro.fechaVigencia || "", 105, 20);
    doc.text("Próxima Revisión:", 140, 20);
    doc.text(registro.proximaRevision || "", 180, 20);

    // Checkboxes
    doc.text(`Prueba: ${checkboxState.prueba ? "Sí" : "No"}`, 10, 40);
    doc.text(`Referencia: ${checkboxState.referencia ? "Sí" : "No"}`, 10, 50);

    // Cronograma de Visitas
    doc.text("Cronograma de Visitas para Recuentos de Pulgas y Garrapatas", 10, 60);
    visitas.forEach((visita, index) => {
      const yPosition = 70 + index * 10;
      doc.text(`${visita.dia}`, 10, yPosition);
      doc.text(`Fecha: ${visita.fecha || ""}`, 40, yPosition);
      doc.text(`Pulgas: ${visita.pulgas || ""}`, 90, yPosition);
      doc.text(`Garrapatas: ${visita.garrapatas || ""}`, 130, yPosition);
      doc.text(`Localización Garrapatas: ${visita.localizacionGarrapatas || ""}`, 180, yPosition);
    });

    // Investigadores y Monitor
    doc.text("Investigador Responsable:", 10, 170);
    doc.text(registro.investigadorResponsable || "", 60, 170);

    doc.text("Investigador Secundario o Coinvestigador:", 10, 180);
    doc.text(registro.investigadorSecundario || "", 60, 180);

    doc.text("Monitor:", 10, 190);
    doc.text(registro.monitor || "", 60, 190);

    doc.save("RegistroVisitas.pdf");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox p={3}>
        <SoftTypography variant="h6" fontWeight="bold" sx={{ fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.6rem' } }} mb={2}>Planilla de Registro de Visitas</SoftTypography>

        <SoftTypography variant="subtitle2" fontWeight="bold" sx={{ fontSize: '0.875rem' }} mb={1}>Datos Generales</SoftTypography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <InputLabel sx={{ fontSize: '0.875rem' }}>Código</InputLabel>
            <SoftInput
              placeholder="P-EFI-AC-003-V0.1"
              value={registro.codigo ?? ''}
              onChange={(e) => setRegistro({ ...registro, codigo: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel sx={{ fontSize: '0.875rem' }}>Fecha de Vigencia</InputLabel>
            <SoftInput
              type="date"
              value={registro.fechaVigencia ?? ''}
              onChange={(e) => setRegistro({ ...registro, fechaVigencia: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel sx={{ fontSize: '0.875rem' }}>Próxima Revisión</InputLabel>
            <SoftInput
              type="date"
              value={registro.proximaRevision ?? ''}
              onChange={(e) => setRegistro({ ...registro, proximaRevision: e.target.value })}
            />
          </Grid>
        </Grid>

        <SoftBox mt={2} ml={2} display="flex" gap={2}>
          <FormControlLabel
            control={<Checkbox checked={checkboxState.prueba} onChange={() => handleFieldChange('prueba')} />}
            label="Prueba"
            sx={{ fontSize: '0.875rem' }}
          />
          <FormControlLabel
            control={<Checkbox checked={checkboxState.referencia} onChange={() => handleFieldChange('referencia')} />}
            label="Referencia"
            sx={{ fontSize: '0.875rem' }}
          />
        </SoftBox>

        <SoftTypography variant="subtitle2" fontWeight="bold" sx={{ fontSize: '0.875rem' }} mt={4} mb={1}>Identificación</SoftTypography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <InputLabel sx={{ fontSize: '0.875rem' }}>N°</InputLabel>
            <SoftInput
              placeholder="N°"
              value={registro.identificacionNumero ?? ''}
              onChange={(e) => setRegistro({ ...registro, identificacionNumero: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel sx={{ fontSize: '0.875rem' }}>Nombre</InputLabel>
            <SoftInput
              placeholder="Nombre"
              value={registro.identificacionNombre ?? ''}
              onChange={(e) => setRegistro({ ...registro, identificacionNombre: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel sx={{ fontSize: '0.875rem' }}>Fecha</InputLabel>
            <SoftInput
              type="date"
              value={registro.identificacionFecha ?? ''}
              onChange={(e) => setRegistro({ ...registro, identificacionFecha: e.target.value })}
            />
          </Grid>
        </Grid>

        {/* Cronograma de Visitas */}
        <SoftTypography variant="subtitle2" fontWeight="bold" sx={{ fontSize: '0.875rem' }} mt={4} mb={1}>Cronograma de Visitas</SoftTypography>
        <SoftBox component={Paper} sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={3} sm={2}><SoftTypography variant="body2" fontWeight="bold">Día</SoftTypography></Grid>
            <Grid item xs={4} sm={2}><SoftTypography variant="body2" fontWeight="bold">Fecha</SoftTypography></Grid>
            <Grid item xs={4} sm={2}><SoftTypography variant="body2" fontWeight="bold">Pulgas</SoftTypography></Grid>
            <Grid item xs={4} sm={2}><SoftTypography variant="body2" fontWeight="bold">Garrapatas</SoftTypography></Grid>
            <Grid item xs={12} sm={4}><SoftTypography variant="body2" fontWeight="bold">Localización Garrapatas</SoftTypography></Grid>

            {visitas.map((visita, index) => (
              <React.Fragment key={index}>
                <Grid item xs={4} sm={1}>{visita.dia}</Grid>

                <Grid item xs={8} sm={2}>
                  <SoftInput
                    type="date"
                    value={visita.fecha}
                    onChange={(e) => {
                      const updatedVisitas = [...visitas];
                      updatedVisitas[index].fecha = e.target.value;
                      setVisitas(updatedVisitas);
                    }}
                    sx={{ width: '100%' }}
                  />
                </Grid>

                <Grid item xs={6} sm={2}>
                  <SoftInput
                    type="number"
                    placeholder="Pulgas"
                    value={visita.pulgas}
                    onChange={(e) => {
                      const updatedVisitas = [...visitas];
                      updatedVisitas[index].pulgas = e.target.value.replace(/\D/, ''); // Solo números
                      setVisitas(updatedVisitas);
                    }}
                    sx={{ width: '100%' }}
                  />
                </Grid>

                <Grid item xs={6} sm={2}>
                  <SoftInput
                    type="number"
                    placeholder="Garrapatas"
                    value={visita.garrapatas}
                    onChange={(e) => {
                      const updatedVisitas = [...visitas];
                      updatedVisitas[index].garrapatas = e.target.value.replace(/\D/, ''); // Solo números
                      setVisitas(updatedVisitas);
                    }}
                    sx={{ width: '100%' }}
                  />
                </Grid>

                <Grid item xs={12} sm={5}>
                  <SoftInput
                    placeholder="Localización Garrapatas"
                    value={visita.localizacionGarrapatas}
                    onChange={(e) => {
                      const updatedVisitas = [...visitas];
                      updatedVisitas[index].localizacionGarrapatas = e.target.value;
                      setVisitas(updatedVisitas);
                    }}
                    sx={{ width: '100%' }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </SoftBox>


        <SoftTypography variant="subtitle2" fontWeight="bold" sx={{ fontSize: '0.875rem' }} mt={4} mb={1}>Investigadores y Monitor</SoftTypography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <InputLabel sx={{ fontSize: '0.875rem' }}>Investigador Responsable</InputLabel>
            <SoftInput
              placeholder="Nombre del Investigador Responsable"
              value={registro.investigadorResponsable ?? ''}
              onChange={(e) => setRegistro({ ...registro, investigadorResponsable: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel sx={{ fontSize: '0.875rem' }}>Investigador Secundario o Coinvestigador</InputLabel>
            <SoftInput
              placeholder="Nombre del Investigador Secundario"
              value={registro.investigadorSecundario ?? ''}
              onChange={(e) => setRegistro({ ...registro, investigadorSecundario: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel sx={{ fontSize: '0.875rem' }}>Monitor</InputLabel>
            <SoftInput
              placeholder="Nombre del Monitor"
              value={registro.monitor ?? ''}
              onChange={(e) => setRegistro({ ...registro, monitor: e.target.value })}
            />
          </Grid>
        </Grid>

        <SoftBox display="flex" justifyContent="center" mt={4}>
          <SoftButton
            variant="contained"
            color="primary"
            onClick={handleGeneratePDF}
            sx={{ width: { xs: '100%', sm: 'auto' } }}
          >
            Generar PDF
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
};

RegistroVisitasForm.propTypes = {
  registro: PropTypes.object,
  setRegistro: PropTypes.func.isRequired,
};

export default RegistroVisitasForm;
